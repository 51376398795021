<template>
  <div class="btn-group">
    <el-tooltip :content="$t('sharelinktxt')" :open-delay="100" placement="top">
      <p-button type="success" v-on:click="modal = true">
        <i class="fa fa-share"></i> {{$t('shareaffl') }}
      </p-button>
    </el-tooltip>

    <modal :show.sync="modal" required headerClasses="justify-content-center">
      <template slot="header">
        <h5> {{ $t('sharelinktxt') }} </h5>
      </template>
      <div class="row">
        <div class="card-body text-center">
          <p-button type="neutral" outline v-on:click="copylink()">
            <i class="fa fa-clone"/> {{ sharelink }}
          </p-button>
        </div>
      </div>
      <template slot="footer">
        <p-button type="success" @click.prevent="modal = false" link> {{ $t('gotit') }}</p-button>
      </template>
    </modal>
  </div>
</template>
<script>
  import {Modal} from 'src/components/UIComponents';
  import {Tooltip} from 'element-ui';
  import Constants from 'src/assets/constants';

  const rules = Constants.methods.getRules();
  const user_data = JSON.parse(localStorage.getItem('user-info'));

  export default {
    components: {
      Modal,
      [Tooltip.name]: Tooltip,
    },
    data () {
      return {
        adminusr: false,
        ueml: null,
        sharelink: null,
        copied: false,
        modal: false
      }
    },
    methods: {
      afflLoaded(resp) {
        if (!resp.success) {
          return;
        }
        const wl = rules.isWhitelabelSession ? `/${rules.whitelabel}` : '';
        this.sharelink = rules.getBaseUrl() + wl + '/register/' + resp.data.affiliateID;
      },
      copylink() {
        const el = document.createElement('textarea');
        el.value = this.sharelink;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        this.copied = true;
        this.$toast.success(this.$t('linkcopied'));
      }
    },
    mounted() {
      if (user_data['roles'].indexOf('admin') >= 0) {
        this.adminusr = true;
      }
      this.ueml = this.adminusr ? this.$route.params.userid : user_data.email
      this.$getAffl(this.ueml).then(this.afflLoaded);
    }
  }

</script>
<style>
.up-top {
  position: relative;
  top: -5px;
}
</style>
